/*
.pricingPlans__planCard{
	-webkit-text-size-adjust: 100%;
	font-size: 1em;
	line-height: 2.35;
	color: #403b3b;
	font-family: Graphik Web,Helvetica Neue,Helvetica,Arial,Verdana,sans-serif;
	border: .175rem solid #dbd9d2;
	box-sizing: border-box;
	position: relative;
	padding: 2.5rem;
	border-left: .125rem solid #dbd9d2;
	width: 109%;
    height: 750px;
	margin: 0;
	
	}
	.pricingPlans__tableWrapper, .pricingPlans--details .currencySelect, .pricingPlans--details .formSelect, .pricingPlans--details .pricingPlans__tableWrapper, .pricingPlans__stickyHeader .currencySelect, .pricingPlans__stickyHeader .formSelect, .pricingPlans__stickyHeader .pricingPlans__tableWrapper {
		margin-left: 0;
		margin-right: 0;
	}
	
	
	.margin--vertical-c4 {
		margin-top: 0rem;
		margin-bottom: 1rem;
	}
	.pricingPlansCalculator .pricingGrid__rowItem {
		display: block;
		line-height: 1.35;
	}
    .pricingPlans__header{
        font-size: 2.125rem;
		font-weight: 600;
		line-height: 0;
		letter-spacing: -.0625rem;
    }
	
	
	.pricingPlans--compare .pricingPlans__planCard .h5, .pricingPlans--details .pricingPlans__planCard .h5, .pricingPlans__stickyHeader .pricingPlans__planCard .h5 {
		font-size: 1.875rem;
	}.copy, .copy p, .flexibleHighlightModule .largeText p, .formLabel--inline, .formLabel--select, .formLabel--select p, .highlightModule--largeText .highlightModule__bodyCopy p, .normalize p {
		font-size: 1.1em;
		letter-spacing: 0;
		line-height: 1.35;
		margin-left: 0%;
	}
    .margin--bottom-c3 {
        margin-bottom: .75rem;
    }
	
	.pricingGrid__rowItem {
		display: table-cell;
		line-height: 1.35;
	}
	.flex--bottom {
		-ms-flex-align: end;
		align-items: flex-end;
		
	}
	
	.flex {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.formattedPrice {
		font-size: 4rem;
		letter-spacing: -.0625rem;
	}
	.formattedPrice {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		color: #241c15;
		font-family: Means Web,Georgia,Times,Times New Roman,serif;
		font-size: 3.125rem;
		font-weight: 300;
		line-height: 1;
		letter-spacing: -.0625rem;
	}
	.formattedPrice1 {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		color: #241c15;
		font-family: Means Web,Georgia,Times,Times New Roman,serif;
		font-size: 2.1rem;
		font-weight: 300;
		line-height: 1;
		margin-top: 25px;
		letter-spacing: -.0625rem;
	}
	.formattedPrice2 {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		color: #241c15;
		font-family: Means Web,Georgia,Times,Times New Roman,serif;
		font-size: 1.5rem;
		font-weight: 200;
		line-height: 1;
		letter-spacing: -.0625rem;
	}
	.flex--left {
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	
	.pricingPlans__planCard .pricingPlans__featuresRow__list {
		width: 100%;
		margin-top: 1.25rem;
		margin-bottom: 1.375rem;
	}
	
	.ul {
		list-style: none;
	}
	
	.margin--bottom-c4 {
		margin-bottom: 1rem;
	}
	.ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 0px;
        list-style-type: none;
	}
	.li {
		list-style-type: none!important;
	}
	.pricingPlans__featuresRow__list li:not(.pricingPlans__featuresRow__list__introText) {
		padding-left: 1.875rem;	
		background: no-repeat 0 url(https://mailchimp.com/release/plums/cxp/images/icon-yes.54c2ab64.svg)  ;
		fill: "red";
	}
	
	
	
	.li-plans:before {
		content: '\2714\0020';
		color: #393939;
		
	  }.pricingPlans--compare .ctaPrimary--fullWidth, .pricingPlans--compare .ctaSecondary--fullWidth, .pricingPlans--details .ctaPrimary--fullWidth, .pricingPlans--details .ctaSecondary--fullWidth, .pricingPlans__stickyHeader .ctaPrimary--fullWidth, .pricingPlans__stickyHeader .ctaSecondary--fullWidth {
        font-size: .875rem;
    }
    .ctaPrimary--nav, .ctaPrimary--small {
        padding: .8125rem;
        min-width: 7.5rem;
    }
    .ctaPrimary {
        color: #fff;
        background-color: #007c89;
        font-family: Graphik Web,Helvetica Neue,Helvetica,Arial,Verdana,sans-serif;
        font-weight: 600;
        font-size: .9375rem;
        padding: 1.25rem 2.5rem;
        -webkit-transition: all .15s linear;
        -o-transition: all .15s linear;
        transition: all .15s linear;
        -webkit-box-shadow: 0 0 0 0.0625rem #007c89 inset;
        box-shadow: inset 0 0 0 0.0625rem #007c89;
    }
    .ctaPrimary--fullWidth, .ctaSecondary--fullWidth, .tagLink--fullWidth {
        display: block;
    }
    .ctaPrimary, .ctaSecondary, .formFileUpload input[type=file]+label, .tagLink {
        display: inline-block;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        border: none;
        background: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
	@media screen and (max-width: 68.75em){
	.pricingPlans>.flex, .pricingPlans__tableWrapper {
		margin-left: 0;
		margin-right: 0;
	}
	
	.pricingPlans>.flex, .pricingPlans__tableWrapper {
		margin-left: -1.875rem;
		margin-right: -1.875rem;
	}}
	@media screen and (min-width: 37.5em){
	.pricingPlans__tableWrapper.flex {
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.pricingPlans__tableWrapper.flex {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.pricingPlans__tableWrapper {
		background-color: #fff;
	}
	.flex {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

*/
.pricingPlans__planCard{
	-webkit-text-size-adjust: 100%;
	font-size: 1em;
	line-height: 2.35;
	color: #403b3b;
	font-family: poppins,sans-serif;
	border: .175rem solid #d4d2db;
	box-sizing: border-box;
	position: relative;
	padding: 2.5rem;
	border-left: .125rem solid #d2dbd4;
	margin: 0;
	
	}
	.formattedPrice2 {
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		color: #241c15;
		font-family: Means Web,Georgia,Times,Times New Roman,serif;
		font-size: 1.5rem;
		font-weight: 200;
		line-height: 1;
		letter-spacing: -.0625rem;
	}
.pricingPlans--compare .currencySelect, .pricingPlans--compare .formSelect, .pricingPlans--compare .pricingPlans__tableWrapper, .pricingPlans--details .currencySelect, .pricingPlans--details .formSelect, .pricingPlans--details .pricingPlans__tableWrapper, .pricingPlans__stickyHeader .currencySelect, .pricingPlans__stickyHeader .formSelect, .pricingPlans__stickyHeader .pricingPlans__tableWrapper {
    margin-left: 0;
    margin-right: 0;
}
.formattedPrice1 {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	color: #241c15;
	font-family: Means Web,Georgia,Times,Times New Roman,serif;
	font-size: 2.1rem;
	font-weight: 300;
	line-height: 1;
	margin-top: 25px;
	letter-spacing: -.0625rem;
}
.pricingPlans__header{
	font-size: 2.125rem;
	font-weight: 600;
	line-height: 0;
	letter-spacing: -.0625rem;
}
@media screen and (max-width: 68.75em){
.pricingPlans>.flex, .pricingPlans__tableWrapper {
    margin-left: 0;
    margin-right: 0;
}
.pricingPlans>.flex, .pricingPlans__tableWrapper {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
}}
@media screen and (min-width: 37.5em){
.pricingPlans__tableWrapper.flex {
    -ms-flex-direction: row;
    flex-direction: row;
}
.pricingPlans__tableWrapper.flex {
    -ms-flex-direction: column;
    flex-direction: column;
}
.pricingPlans__tableWrapper {
    background-color: #fff;
}
.flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}}
.pricingPlans__header{
	font-size: 2.125rem;
	font-weight: 600;
	line-height: 1;
	letter-spacing: -.0625rem;
}
@media screen and (min-width: 52.5em){
.pricingPlansCalculator .pricingPlans .pricingPlans__planCard {
    width: 25%;
    margin: 0;
}
}
@media screen and (min-width: 37.5em){
.pricingPlansCalculator .pricingPlans .pricingPlans__planCard {
    width: 100%;
    margin: 1.25rem 0;
    border-left: .125rem solid #dbd9d2;
}
.pricingPlansCalculator .pricingPlans .pricingPlans__planCard {
    padding: 2.5rem;
}}
@media screen and (min-width: 37.5em){
.pricingPlans__planCard:first-child {
    border-left: .125rem solid #dbd9d2;
}}
@media screen and (min-width: 37.5em){
.pricingPlans__planCard {
    border-left: 0;
    margin: 0;
    width: 25%;
}
.pricingPlans__planCard {
    border: .125rem solid #dbd9d2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 3.125rem 1.25rem 1.25rem;
    margin: 1.25rem 0;
    position: relative;
	
}}
.margin--vertical-c4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}.pricingPlansCalculator .pricingGrid__rowItem {
    display: block;
}

@media only screen and (max-width: 1024px) {
    .quick-view-modal {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .quick-view-modal {
        width: 97%;
        /* padding: 2px 2px 2px 2px; */
    }
}

@media only screen and (max-width: 1240px) {
    .banner-height {
        height: 100% !important;
    }
    .banner-overlay-height {
        height: 100% !important;
    }
}

@media only screen and (min-width: 1565px) {
    .padding-left-right-10p {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.min-height-255 {
    min-height: 255px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .min-height-210 {
        min-height: 210px;
    }
    .padding-10-xs {
        padding: 10px;
    }
    
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .make-width-large {
     width: 81%!important;
  }
  
}
@media only screen and (min-width: 992px) and (max-width: 1102px) {
  .make-height-1070 {
     height: 1070px!important;
  }
  
}

@media screen and (min-width: 37.5em){
.pricingGrid__rowItem {
    display: table-cell;
}
.pricingGrid__rowItem {
    display: block;
}}
.pricingPlans--compare .pricingPlans__planCard .h5, .pricingPlans--details .pricingPlans__planCard .h5, .pricingPlans__stickyHeader .pricingPlans__planCard .h5 {
    font-size: 1.875rem;
}

@media screen and (min-width: 37.5em){
.formLabel, .globalNav__shoppingCart__price, .h5, .helpArticle__section.normalize h3, .normalize .h5, .normalize h4, .searchBar__textInput {
    font-size: 1.25rem;
    letter-spacing: 0;
}
.formLabel, .globalNav__shoppingCart__price, .h5, .helpArticle__section.normalize h3, .normalize .h5, .normalize h4, .searchBar__textInput {
    color: #241c15;
    font-family: Means Web,Georgia,Times,Times New Roman,serif;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: 0;
}

}
.pricingPlans__planCard .copy, .pricingPlans__planCard .formLabel--select {
    font-size: .875rem;
}

@media screen and (min-width: 37.5em){
.copy, .copy p, .flexibleHighlightModule .largeText p, .formLabel--inline, .formLabel--select, .formLabel--select p, .highlightModule--largeText .highlightModule__bodyCopy p, .normalize p {
    font-size: 1em;
    letter-spacing: 0;
}
.copy, .copy p, .flexibleHighlightModule .largeText p, .formLabel--inline, .formLabel--select, .formLabel--select p, .highlightModule--largeText .highlightModule__bodyCopy p, .normalize p {
    color: #403b3b;
    font-family: Roboto, sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
}
.margin--top-c4 {
    margin-top: 1rem;
	
}}
.margin--vertical-c4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.margin--bottom-c1 {
    margin-bottom: .25rem;
}
.margin--bottom-c4 {
    margin-top: 0;
}
.flex--bottom {
    -ms-flex-align: end;
    align-items: flex-end;
}

.flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.screen-reader-only {
    border: 0!important;
    -webkit-clip-path: inset(50%)!important;
    clip-path: inset(50%)!important;
    clip: rect(1px,1px,1px,1px)!important;
    height: 1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important;
    white-space: nowrap!important;
}
.screen-reader-only {
    border: 0!important;
    -webkit-clip-path: inset(50%)!important;
    clip-path: inset(50%)!important;
    clip: rect(1px,1px,1px,1px)!important;
    height: 1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 1px!important;
    white-space: nowrap!important;
}
@media screen and (min-width: 37.5em){
.formattedPrice {
    font-size: 4rem;
    letter-spacing: -.0625rem;
}

.formattedPrice {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    color: #241c15;
    font-family: Means Web,Georgia,Times,Times New Roman,serif;
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.0625rem;
}
.container-fluid{
  font-family: Roboto, sans-serif;
}
.flex--left {
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
}
@media screen and (min-width: 37.5em){
.pricingPlans__planCard .formattedPrice__price--integer {
    font-size: 6.06666667vw;
}

.pricingPlans__planCard .formattedPrice__price--integer {
    font-size: 3.25rem;
}}
@media screen and (min-width: 37.5em){
.pricingPlans--compare .formattedPrice__price--integer, .pricingPlans--details .formattedPrice__price--integer, .pricingPlans__stickyHeader .formattedPrice__price--integer {
    font-size: 3.96666667vw;
}
.pricingPlans--compare .formattedPrice__price--integer, .pricingPlans--details .formattedPrice__price--integer, .pricingPlans__stickyHeader .formattedPrice__price--integer {
    font-size: 2.125rem;
}
.formattedPrice__price--integer {
    line-height: .7;
    font-weight: 400;
}}
.flexibleHighlightModule .copy-info, .formattedPrice__disclaimerAsterisk {
    font-family: Graphik Web,Helvetica Neue,Helvetica,Arial,Verdana,sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
}

.formattedPrice__disclaimerAsterisk {
    margin-left: .3125rem;
}
.pricingPlans--compare .experiment__control, .pricingPlans--details .experiment__control, .pricingPlans__stickyHeader .experiment__control {
    display: none;
}
.margin--bottom-c3 {
    margin-bottom: .75rem;
}
.pricingPlans--compare .ctaPrimary--fullWidth, .pricingPlans--compare .ctaSecondary--fullWidth, .pricingPlans--details .ctaPrimary--fullWidth, .pricingPlans--details .ctaSecondary--fullWidth, .pricingPlans__stickyHeader .ctaPrimary--fullWidth, .pricingPlans__stickyHeader .ctaSecondary--fullWidth {
    font-size: .875rem;
}

.ctaPrimary--nav, .ctaPrimary--small {
    padding: .8125rem;
    min-width: 7.5rem;
}
.pricingPlans__tableWrapper {
    background-color: #fff;
}
.ctaPrimary {
    color: #fff;
    background-color: #007c89;
    font-family: Graphik Web,Helvetica Neue,Helvetica,Arial,Verdana,sans-serif;
    font-weight: 600;
    font-size: .9375rem;
    padding: 1.25rem 2.5rem;
    -webkit-transition: all .15s linear;
    -o-transition: all .15s linear;
    transition: all .15s linear;
    -webkit-box-shadow: 0 0 0 0.0625rem #007c89 inset;
    box-shadow: inset 0 0 0 0.0625rem #007c89;
}
.ctaPrimary--fullWidth, .ctaSecondary--fullWidth, .tagLink--fullWidth {
    display: block;
}
.ctaPrimary, .ctaSecondary, .formFileUpload input[type=file]+label, .tagLink {
    display: inline-block;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.pricingPlans__planCard div:nth-child(3) {
    color: rgba(36,28,21,.65);
}

.margin--vertical-c4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.pricingPlans__planCard .pricingPlans__featuresRow__list {
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 1.375rem;
}

ul {
    list-style: none;
    position:initial;
}

.pricingPlans__featuresRow__list li:not(.pricingPlans__featuresRow__list__introText) {
	padding-left: 1.875rem;	
	background: no-repeat 0 url(https://mailchimp.com/release/plums/cxp/images/icon-yes.54c2ab64.svg)  ;
    background-position-y: 8px;
}
.pricingPlans--compare .currencySelect, .pricingPlans--compare .formSelect, .pricingPlans--compare .pricingPlans__tableWrapper, .pricingPlans--details .currencySelect, .pricingPlans--details .formSelect, .pricingPlans--details .pricingPlans__tableWrapper, .pricingPlans__stickyHeader .currencySelect, .pricingPlans__stickyHeader .formSelect, .pricingPlans__stickyHeader .pricingPlans__tableWrapper {
    margin-left: 0;
    margin-right: 0;
}

@media screen and (max-width: 68.75em){
.pricingPlans>.flex, .pricingPlans__tableWrapper {
    margin-left: 0;
    margin-right: 0;
}
.pricingPlans>.flex, .pricingPlans__tableWrapper {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
}
}
@media screen and (min-width: 37.5em){
.pricingPlans__tableWrapper.flex {
    -ms-flex-direction: row;
    flex-direction: row;
}
.pricingPlans__tableWrapper.flex {
    -ms-flex-direction: column;
    flex-direction: column;
}
.pricingPlans__tableWrapper {
    background-color: #fff;
}
.flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
}
@media only screen and (max-width:767px){

.content_area {
    font-family: Gotham Pro medium;
    margin: 0;
    font-weight: 400;
    color: #000;
    font-size: 24px;
    line-height: 46px;
    margin-bottom: 35px;
    text-transform: capitalize;}
    
    .margin--vertical-c4 {
        height: 60px!important;
    }
    .pricingPlans__featuresRow__list{
        margin: 90px 0;
    }
     .margin--top-c4{
        margin: 24px 0;}
     .pricingPlans__header{
         font-size: 20px!important;
     }   
     .box-sizes{
         height: 675px;
     }
     .copy-regular{
        height: 20px!important;
        margin-left: 0px;
     }
}

/* @media only screen (min-width:768px)and (max-width:991px){
} */
/* #root {
    display: flex;
    align-items: center;
  }
   */

   .checkout .vertical::before{
    height: 100%;
    width: 50%;
    background: #fff;
    position: fixed;
    content: " ";
    top: 0px;
    right: 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    -webkit-box-shadow: 10px 0 4px -4px rgba(250, 250, 250, 1) inset;
    -moz-box-shadow: 10px 0 4px -4px rgb(250, 250, 250, 1) inset;
    box-shadow: 10px 0 14px -4px rgb(250, 250, 250, 1) inset;
   }
     
  
   .checkout form {
    width: 30vw;
    align-self: center;
    /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
      /* box-shadow: 15px 0 30px 0 rgba(0,0,0,.18); */
       /* -webkit-box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset;
          -moz-box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset;
          box-shadow: 7px 0 4px -4px rgb(0, 0, 0 ,.18) inset; */
    border-radius: 0px;
    margin-top: 25px;
    margin-left: 50px!important;
    /* //margin-left: 45px; */
  }
  
  .checkout input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  .checkout .result-message  {
    line-height: 22px;
    font-size: 16px;
  }
  
  .checkout .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .checkout .hidden {
    display: none;
  }
  
  .checkout #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  .checkout #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
  }
  
  .checkout #payment-request-button {
    margin-bottom: 32px;
  }
  
  /* Buttons and links */
  .checkout button {
    background: #5469d4;
    font-family:poppins,sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  .checkout button:hover {
    filter: contrast(115%);
  }
  
  .checkout button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .checkout .spinner,
  .checkout .spinner:before,
  .checkout .spinner:after {
    border-radius: 50%;
  }
  
  .checkout .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .checkout .spinner:before,
  .checkout .spinner:after {
    position: absolute;
    content: "";
  }
  
  .checkout .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .checkout .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    .checkout form {
      width: 80vw;
    }
  }
  @media only screen and (max-width: 599px) {
    .price_plans_less {
      font-size: 33px;
    }
    .copy-regular {
        font-size: 20px;
       
    }
  }


  /* css for the card payment start */
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  .hidden {
    display: none;
  }
  #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
    }
  }
  @media only screen and (max-width: 600px) {
    .reduce-right-margin {
      width: 100vw;
    }
  }
  @media only screen and (max-width: 458px) {
    .d-flex{
        display: block!important;
       }
      
  }
  @media only screen and (max-width: 991px) {
    .reduce-table-height{
        height: 800px!important;
       }
      
  }
  @media only screen and (max-width: 991px) {
    .reduce-table-height-1{
        height: 700px!important;
       }
      
  }
  