.body-overlay {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: -1;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    -webkit-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    transition: opacity .3s ease;
}

#header.full-header {
    border-bottom-color: #EEE;
}

#header.transparent-header {
    background: transparent;
    border-bottom: none;
    z-index: 199;
}

#header, #header-wrap, #logo img {
    height: 70px;
    -webkit-transition: height .4s ease, opacity .3s ease;
    -o-transition: height .4s ease, opacity .3s ease;
    transition: height .4s ease, opacity .3s ease;
}

#header {
    position: relative;
    background-color: #FFF;
    border-bottom: 1px solid #F5F5F5;
}

#header.full-header:not(.border-full-header):not(.sticky-header) #header-wrap {
    border-bottom: 0;
}

#header.transparent-header:not(.sticky-header) #header-wrap {
    background-color: transparent;
}

#header.transparent-header.full-header #header-wrap {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

#header #header-wrap {
    z-index: 199;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    -webkit-transition: height .4s ease, background-color .4s ease, opacity .3s ease;
    -o-transition: height .4s ease, background-color .4s ease, opacity .3s ease;
    transition: height .4s ease, background-color .4s ease, opacity .3s ease;
}

#header, #header-wrap, #logo img {
    height: 70px;
    -webkit-transition: height .4s ease, opacity .3s ease;
    -o-transition: height .4s ease, opacity .3s ease;
    transition: height .4s ease, opacity .3s ease;
}

#header-wrap {
    position: relative;
    z-index: 199;
    -webkit-backface-visibility: hidden;
}

#header, #header-wrap, #logo img {
    height: 100px;
    -webkit-transition: height .4s ease, opacity .3s ease;
    -o-transition: height .4s ease, opacity .3s ease;
    transition: height .4s ease, opacity .3s ease;
}

#body .side-push-panel.stretched #header .container {
    right: 0;
}

#header.transparent-header + #slider, #header.transparent-header + #page-title.page-title-parallax, #header.transparent-header + #google-map, #slider + #header.transparent-header {
    top: -70px;
    margin-bottom: -70px;
}

.slider-parallax {
    position: relative;
}

/* .slider-element {
    position: relative;
    width: 100%;
    overflow: hidden;
}

section {
    display: block;
} */

/* #header.transparent-header + .slider-parallax .slider-parallax-inner {
    top: 0;
}

body:not(.side-header) .slider-parallax .slider-parallax-inner {
    left: 0;
}

.slider-parallax .slider-parallax-inner {
    position: fixed;
    width: 100%;
    height: 100%;
} */

.subscribe-widget {
    position: relative;
    /* top: 50%; */
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: -250px; */
    opacity: 1.42238;
}

.subscribe-widget-right {
    position: relative;
    /* top: 50%; */
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    /* margin-top: -191.875px; */
    opacity: 1.42238;
}

.vertical-middle {
    height: auto !important;
    left: 0;
    max-width: none !important;
    z-index: 2;
}

.heading-block > span:not(.before-heading) {
    display: block;
    margin-top: 10px;
    font-weight: 300;
    color: #474747;
}

#slider-subscribe-form {
    width: 100%;
}

#slider-subscribe-form .input-group {
    background-color: #FFF;
    border: 1px solid #EEE;
    border-radius: 3px;
    box-shadow: 0 0 30px 4px rgba(0,0,0,0.15);
    border-left: 3px solid #4c9cd3;
}

#slider-subscribe-form .input-group {
    align-items: center;
}

.form-control {
    border-radius: 3px;
}

#slider-subscribe-form input, #slider-subscribe-form select {
    box-shadow: none;
    border: 0;
    height: 45px;
    padding-left: 1.25rem;
}

.nobottommargin {
    margin-bottom: 0 !important;
}

.emphasis-title {
    margin: 0 0 50px;
}

.dark .emphasis-title:not(.not-dark) h1 {
    /* color: #FFF; */
}

.emphasis-title h1 {
    font-size: 64px;
    letter-spacing: -2px;
    font-weight: 400;
}

.margin-top-15 {
    margin-top: 15px;
}

/* .registrationBackImg {
    background: url(./registrationBackImg.jpg) center center / cover no-repeat;
    background-size: cover;
    height: 500px;
} */

.padding-top-20 {
    padding-top: 20px;
}

.button.button-circle {
    border-radius: 20px;
}

.margin-0 {
    margin: 0px;
}