.navbar-custom {
    position: relative;
    display: block;
    flex-wrap: unset;
    align-items: initial;
    justify-content: unset;
    padding: inherit;
}

input[type="text"], input[type="date"], input[type="email"], input[type="search"], input[type="password"], input[type="number"], input[type="tel"], .custom-select-box {
    padding: 6px 12px;
    font-size: 0.875rem;
    height: 33.5px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #ced4da;
}

label {
    display: inline-block;
    white-space: inherit;
}

.custom-select {
    height: 30px;
    margin-left: 0px!important  ;
}

.pagination {
    display: inline-block;
}

.pagination li a, .pagination li:first-child a, .pagination li:last-child a {
    border-radius: 0;
}

.pagination li a {
    margin-left: 0px;
    font-weight: inherit;
    height: auto;
    min-width: 40px;
    width: auto;
    padding: 0px 10px;
}

.pagination>li>a, .pagination>li>span {
    border: 1px solid #ddd;
}

table thead:last-child {
    display: none;
}

.page-item.active .page-link {
    background-color: #367fa9;
    border-color: #367fa9;
}

.navbar-nav>.user-menu>.dropdown-menu>.user-footer .btn-default {
    color: #ffffff;
    background-color: #08406e;
}

.content-wrapper {
    background-color: white;
}

/* custom css for datatable */
.sc-ifAKCX {
    justify-content: left;
}
.rdt_TableHeader {
    display: none !important;
}
.rdt_TableCol:first-child, .rdt_TableCell:first-child {
    justify-content: start;
}
.jDHQPG {
    display: block !important;
}

.hLrWNh {
    color: rgba(0,0,0,.87) !important;
}

.inhAeF, .hLrWNh:hover {
    color: #3c8dbc !important;
}


/* css added by nayana */

.skin-blue .main-header .logo{
    background-color: #0094e9;
}

.skin-blue .sidebar-menu > li.header {
    color: #fff;
    /* background-color: #55595a; */
}

.shoping-cart-number{
    background-color: #0094e9;
    position: absolute;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    top: 15px;
    color: #fff;
    right: 5px;
}

.fs-30{
    font-size: 30px!important;
    color: #053359;
}

.radio-inline{
    display: inline-flex!important;
    align-items: center;
    padding: 0;
}

.radio-input{
    position: relative!important;
    margin: 0 10px 0 0!important;
}

.m-r-15{
    margin-right: 15px;
}

/* .main-sidebar{
    position: fixed;
} 



.skin-blue .sidebar a{
    color: #000;
}

.skin-blue .sidebar a:hover{
    color: rgba(0,0,0,.87);
}

.skin-blue .main-sidebar{
    background-color: #fff;
}



.skin-blue .user-panel>.info {
    color: rgba(0,0,0,.87);
}

.skin-blue .user-panel>.info>a{
    color: rgba(0,0,0,.87);
}

.vertical-line{
    border-right: 4px solid #55595a;
    position: absolute;
    left: 0%;
} */