body, h1, h2, h3, h4, h5 {
    font-family: poppins,sans-serif;
}

#content {
    position: relative;
    overflow: hidden;
    background-color: #FFF;
}

/* .backgroundImg {
    min-height: 450px;
    background-image: url(./skywalker-banner.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
} */

.content-wrap {
    position: relative;
    padding: 80px 0;
}

.section {
    position: relative;
    margin: 20px 0;
    padding: 60px 0;
    /* background-color: #F9F9F9; */
    overflow: hidden;
}

.nobg {
    background: none !important;
}

.ls--2 {
    letter-spacing: -2px;
}

.t600 {
    font-weight: 600 !important;
}

h1 > span:not(.nocolor):not(.badge), h2 > span:not(.nocolor):not(.badge), h3 > span:not(.nocolor):not(.badge), h4 > span:not(.nocolor):not(.badge), h5 > span:not(.nocolor):not(.badge), h6 > span:not(.nocolor):not(.badge) {
    color: #4c9cd3;
}

h1 > span:not(.nocolor):not(.badge), h2 > span:not(.nocolor):not(.badge), h3 > span:not(.nocolor):not(.badge), h4 > span:not(.nocolor):not(.badge), h5 > span:not(.nocolor):not(.badge), h6 > span:not(.nocolor):not(.badge) {
    color: #4c9cd3;
}

.text-rotater > .t-rotate > .animated {
    display: inline-block;
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

#content p {
    line-height: 1.8;
}

.parallax {
    /* background-color: transparent; */
    /* background-attachment: fixed;
    background-position: 50% 50%; */
    /* background-repeat: no-repeat; */
    /* overflow: hidden; */
    /* will-change: transform; */
}

.ohidden {
    position: relative;
    overflow: hidden !important;
}

.hero-features {
    /* transform: translateY(-50%); */
    padding: 40px 30px;
    max-width: 100%;
    margin-left: auto;
    /* border-top-left-radius: 6px; */
    /* border-bottom-left-radius: 6px; */
    border-left: 4px solid rgba(0, 0, 0, .2);
}

.dark, .dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
    color: #EEE;
}

.bgcolor, .bgcolor #header-wrap {
    /* background-color: #4c9cd3 !important; */
    background-color: white !important;
}

.feature-box.fbox-plain.fbox-small {
    padding-left: 50px;
    margin-bottom: 40px;
}

.feature-box.fbox-plain.fbox-small {
    padding-left: 42px;
}

.feature-box:first-child {
    margin-top: 0;
}

.feature-box {
    position: relative;
    margin-top: 20px;
    padding: 0 0 0 80px;
}

.feature-box.fbox-plain.fbox-small .fbox-icon {
    width: 28px;
}

.feature-box.fbox-plain .fbox-icon {
    border: none !important;
    height: auto !important;
}

.feature-box .fbox-icon {
    top: 3px;
}

.feature-box .fbox-icon {
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 0;
}

.dark .feature-box.fbox-plain.fbox-dark .fbox-icon i {
    color: #FFF;
}

.dark .feature-box.fbox-dark .fbox-icon i, .dark .feature-box.fbox-dark .fbox-icon img {
    background-color: rgba(255,255,255,0.1);
}

.feature-box.fbox-plain.fbox-dark .fbox-icon i, .feature-box.fbox-plain.fbox-dark .fbox-icon img {
    color: #444;
}

.feature-box.fbox-plain.fbox-small .fbox-icon i {
    font-size: 28px;
}

.feature-box.fbox-plain .fbox-icon i {
    font-size: 48px;
    line-height: 1 !important;
}

.feature-box.fbox-plain .fbox-icon i, .feature-box.fbox-plain .fbox-icon img {
    border: none !important;
    background-color: transparent !important;
    color: #FFFFFF;
    border-radius: 0;
}

.feature-box.fbox-dark .fbox-icon i, .feature-box.fbox-dark .fbox-icon img {
    background-color: #333;
}

.feature-box .fbox-icon i {
    font-style: normal;
    font-size: 28px;
    text-align: center;
    line-height: 64px;
}

.feature-box .fbox-icon i, .feature-box .fbox-icon img {
    border-radius: 50%;
    background-color: #4c9cd3;
}

.feature-box .fbox-icon a, .feature-box .fbox-icon i, .feature-box .fbox-icon img {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    color: #FFF;
}

.hero-features i {
    font-size: 24px;
    line-height: 1.4;
}

.feature-box.fbox-plain.fbox-small h3 {
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 10px;
}

.dark .feature-box h3 {
    color: #CCC;
}

.dark, .dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
    color: #EEE;
}

.feature-box h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;
    /* color: #333; */
}

/* .backgroundImg1 {
    background: url(./5.jpg) center center no-repeat;
    background-size: cover;
} */

.backgroundImg2 {
    background-color: #eee!important;
    background-size: cover;
}

.nopadding {
    padding: 0!important;
}

.nomargin {
    margin: 0!important;
}

.col-padding {
    padding: 60px;
}

.dark .heading-block:not(.not-dark), .dark .counter {
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
}

.heading-block, .heading-block1 {
    margin-bottom: 25px;
}

.noborder {
    border: none!important;
}

.dark .i-plain {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
}

.i-xlarge.i-plain {
    width: 64px!important;
    height: 64px!important;
    font-size: 56px;
    line-height: 64px!important;
}

.i-xlarge.i-bordered, .i-xlarge.i-circled, .i-xlarge.i-plain, .i-xlarge.i-rounded {
    margin: 4px 11px 7px 0;
    font-size: 56px;
}

.heading-block h3 {
    font-size: 26px;
}

#content p {
    line-height: 1.8;
}

.heading-block~p:not(.lead) {
    font-size: 15px;
    opacity: 1;
}

address, blockquote, dd, dl, fieldset, form, ol, p, pre, table, ul {
    margin-bottom: 30px;
}

.button.button-rounded {
    border-radius: 3px;
}

.button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    margin: 5px;
    padding: 0 22px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    background-color: #4c9cd3;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
}

.button-white {
    background-color: #f9f9f9;
}

.button-light {
    background-color: #eee;
    color: #333;
    text-shadow: none!important;
}

a {
    text-decoration: none!important;
    color: #4c9cd3;
}

.dark .button-light:not(.button-border):hover {
    background-color: #282828;
}

.dark a:hover {
    color: #eee;
}

.bgcolor1 {
    background-color: white!important;
}

.button.button-large {
    padding: 0 26px;
    font-size: 16px;
    height: 46px;
    line-height: 46px;
}

.button-blue {
    background-color: #034669;
}

.button.button-blue:hover {
    background-color: #282828;
}

.button:hover {
    background-color: #444;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.2);
}

.nobottomborder {
    border-bottom: none!important;
}

.heading-block1 h3 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #333;
}

.button.button-border-thin.button-small {
    line-height: 32px;
}

.button.button-border-thin {
    border-width: 1px;
    line-height: 38px;
}

.button.button-border-login {
    border: 2px solid #444;
    background-color: transparent;
    color: #333;
    line-height: 36px;
    font-weight: 700;
    text-shadow: none;
}

.button.button-border {
    border: 2px solid #FFFFFF;
    background-color: transparent;
    color: #FFFFFF;
    line-height: 36px;
    font-weight: 700;
    text-shadow: none;
}

.button.button-small {
    padding: 0 17px;
    font-size: 12px;
    height: 34px;
    line-height: 34px;
}

.owl-carousel {
    display: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
    width: 100%;
}

.owl-carousel.owl-loaded {
    display: block;
}

.bottommargin-lg {
    margin-bottom: 80px!important;
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
}

.real-estate-item {
    position: relative;
}

.real-estate-item-image {
    position: relative;
    width: 100%;
}

.real-estate-item-image:before {
    position: absolute;
    left: 0;
    content: '';
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 50%;
    /* background: -moz-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,rgba(0,0,0,0)),color-stop(100%,rgba(0,0,0,0.85)));
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
    background: -o-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
    background: -ms-linear-gradient(top,rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000',endColorstr='#a6000000',GradientType=0 );
}

.bgcolor-2 {
    background-color: #f0ad4e!important;
}

.real-estate-item-image>a, .real-estate-item-image>img {
    display: block;
    width: 100%;
    height: auto;
}

#content .container {
    position: relative;
}

.section .container {
    z-index: 2;
}

.play-video i {
    position: absolute;
    /* padding-left: 5px; */
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    line-height: 82px;
    border-radius: 50%;
    z-index: 1;
    background-color: #267df4;
    /* background-color: var(--themecolor); */
    text-align: center;
    font-size: 80px;
    color: #fff;
    margin-top: -40px;
    margin-left: -40px;
    transition: transform .3s ease-out;
    -webkit-backface-visibility: hidden;
}

.feature-box.fbox-plain.fbox-small {
    padding-left: 50px;
    margin-bottom: 40px;
}

.ls0 {
    letter-spacing: 0!important;
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

body:not(.device-touch) .button {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.button.button-xlarge {
    padding: 0 16px;
    font-size: 14px;
    letter-spacing: 2px;
    height: 52px;
    line-height: 52px;
}

.button:hover, #top-account:hover a, #image-carousel .carousel-grid-item:hover a.item {
    box-shadow: 0 5px 8px rgba(0,0,0,.2);
    transform: translateY(-3px);
    opacity: .95;
}

#footer .footer-widgets-wrap {
    position: relative;
    padding: 40px 0;
}

.widget:first-child {
    margin-top: 0;
}

.widget {
    position: relative;
    margin-top: 50px;
}

.dark .widget h4 {
    color: #ddd;
}

.dark, .dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
    color: #eee;
}

.widget>h4 {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#footer .list-unstyled li a {
    opacity: .7;
    color: #fff;
    font-weight: 300;
}

.dark .line, .dark .double-line {
    border-top-color: rgba(255,255,255,.1);
}
.double-line, .line {
    clear: both;
    position: relative;
    width: 100%;
    margin: 60px 0;
    border-top: 1px solid #eee;
}

body:not(.device-touch) .i-bordered, body:not(.device-touch) .i-circled, body:not(.device-touch) .i-plain, body:not(.device-touch) .i-rounded, body:not(.device-touch) .social-icon {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.dark .si-light {
    background-color: rgba(255,255,255,.1);
    color: #eee!important;
    border-color: transparent;
}

.dark .social-icon {
    color: #ddd!important;
    border-color: #ddd;
}

.dark .social-icon:hover {
    color: #fff!important;
    border-color: transparent;
}

.social-icon.si-small {
    width: 32px;
    height: 32px;
    font-size: 14px;
    line-height: 30px!important;
}

.si-light {
    background-color: rgba(0,0,0,.05);
    color: #666!important;
    border-color: transparent;
}

.social-icon {
    margin: 0 5px 5px 0;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 38px!important;
    color: #555;
    text-shadow: none;
    border: 1px solid #aaa;
    border-radius: 3px;
    overflow: hidden;
}

.i-bordered, .i-circled, .i-plain, .i-rounded, .social-icon {
    display: block;
    float: left;
    margin: 4px 11px 7px 0;
    text-align: center!important;
    font-size: 28px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.3);
    cursor: pointer;
    font-style: normal;
}

.si-colored.si-facebook, .si-facebook:hover {
    background-color: #3b5998!important;
}

body:not(.device-touch) .social-icon i {
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.social-icon i {
    display: block;
    position: relative;
}

ul {
    margin: 0;
    padding: 0;
}

ul {
    margin-bottom: 30px;
}

ul.list-numbers li {
    list-style: decimal inside;
    margin-bottom: 20px;
}

#copyrights {
    padding: 20px 0;
    background-color: #ddd;
    font-size: 14px;
    line-height: 1.8;
}

#copyrights a:first-child {
    margin-left: 0;
}

.dark .copyright-links a {
    color: rgba(255,255,255,.25);
    border-bottom-color: rgba(255,255,255,.25);
}

.copyright-links a {
    display: inline-block;
    margin: 0 3px;
    color: #333;
    border-bottom: 1px dotted #444;
}

.dark .copyright-links a:hover {
    color: rgba(255,255,255,.35);
    border-bottom-color: rgba(255,255,255,.35);
}

.copyright-links a:hover {
    color: #555;
    border-bottom: 1px solid #666;
}

.img-column, .real-estate-item {
    position: relative;
    display: inline-block;
    width: 370px;
    padding: 5px;
}

.img-row {
    clear: both;
    text-align: center;
}

.real-estate-item-image {
    position: relative;
    width: 100%;
}

.real-estate-item-image .badge {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 11px;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
}

.bgcolor-2 {
    background-color: #f0ad4e!important;
}

.real-estate-item-image>a, .real-estate-item-image>img {
    display: block;
    width: 100%;
    height: auto;
}

.real-estate-item-price {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 1;
    font-size: 26px;
    line-height: 1;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
}

.real-estate-item-price span {
    /* display: block; */
    margin-top: 3px;
    /* font-weight: 300; */
    font-size: 12px;
    color: rgba(255,255,255,.85);
    text-align: right;
}

.real-estate-item-info {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1;
}

.real-estate-item-info a {
    display: block;
    float: left;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    padding: 8px;
    background-color: rgba(0,0,0,.25);
    border-radius: 4px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.real-estate-item-desc {
    position: relative;
    margin-top: 20px;
    padding: 0 20px;
}

.real-estate-item-desc h3 {
    font-size: 13px;
}

.real-estate-item-desc h3 a {
    color: #333;
}

.real-estate-item-desc h3+span {
    display: block;
    margin-top: -27px;
    color: #999;
}

.real-estate-item-link {
    position: absolute;
    top: 2px;
    right: 20px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    font-size: 16px;
    color: #555;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    border: 1px solid #555;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.real-estate-item-features {
    margin-top: 15px;
}

.real-estate-item-features i {
    position: relative;
    margin-left: 3px;
}

.real-estate-item-info a:not(:first-child) {
    margin-left: 5px;
}
.real-estate-item-info a {
    display: block;
    float: left;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0,0,0,.1);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    padding: 8px;
    background-color: rgba(0,0,0,.25);
    border-radius: 4px;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

.image-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.1); */
    /* background-color: #619ACE;
    background: linear-gradient(133.59deg, #9ACAE9 0%, #619ACE 100%);
    opacity: 0.8; */
}

.text-black {
    color: #000000 !important;
}
.text-white {
    color: white;
}