/* @import url(//db.onlinewebfonts.com/c/0be7748549934c0e481bdb7b8ba5270f?family=Gotham+Pro+Black);
@import url(//db.onlinewebfonts.com/c/059288033e0f23aa4c5b19f0965ccc0d?family=Gotham+Pro+Medium);
@import url(//db.onlinewebfonts.com/c/07a38bbad54db72a40b406bed1c72f53?family=Gotham+Pro);  */

/* https://skywalker-dev.s3.amazonaws.com/fonts/ */



/* 
@font-face {font-family: "Gotham Pro Black";
    src: url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.eot"); 
    src: url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/0be7748549934c0e481bdb7b8ba5270f.svg#Gotham Pro Black") format("svg"); 
}

@font-face {font-family: "Gotham Pro Medium";
    src: url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.eot"); 
    src: url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.woff2") format("woff2"), 
    url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/059288033e0f23aa4c5b19f0965ccc0d.svg#Gotham Pro Medium") format("svg"); 
}



@font-face {font-family: "Gotham Pro";
    src: url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.eot"); 
    src: url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.eot?#iefix") format("embedded-opentype"), 
    url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.woff") format("woff"), 
    url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.ttf") format("truetype"), 
    url("//db.onlinewebfonts.com/t/07a38bbad54db72a40b406bed1c72f53.svg#Gotham Pro") format("svg"); 
} */




.font-Gotham-Pro-Black {
    font-family: poppins, sans-serif;
    font-weight: bold !important;
}

.font-Gotham-Pro-Medium {
    font-family: poppins, sans-serif;
    font-weight: bold !important;
}

.font-Gotham-Pro-Regular {
    font-family: poppins, sans-serif;
}

.font-weight-bold-imp {
    font-weight: bold !important;
}

.font-weight-normal {
    font-weight: none;
}

.font-weight-normal-imp {
    font-weight: normal !important;
}

body {
    font-family: 'Roboto Condensed', sans-serif;
}

#footer.dark,
.dark #footer {
    background-color: #333;
    color: #CCC;
    border-top-color: rgba(0, 0, 0, 0.2);
}

#footer .widget>h4 {
    /* margin-bottom: 50px; */
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.button.button-border.button-light {
    border-color: #F9F9F9;
    color: #F9F9F9;
}

#footer .widget ul.footer-site-links li {
    margin: 5px 0;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 300;
}

.dark #copyrights {
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.25);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

#copyrights {
    padding: 40px 0;
    background-color: #DDD;
    font-size: 14px;
    line-height: 1.8;
}

/* #page-footer {
    display: block;
    background-color: #fff;
    border-top: 2px solid #f4f4f2;
    height: 1px;
    margin-top: 30px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

#page-footer1 .inner .footer-bottom .to-top {
    border-radius: 50%;
    border: 2px solid #4c9cd3;
    display: block;
    font-size: 18px;
    height: 30px;
    line-height: 22px;
    position: relative;
    text-align: center;
    width: 30px;
}

#page-footer1 .inner .footer-bottom .right {
    float: right;
} */

.breadcrumb {
    margin-bottom: 0px;
}

.padding-0 {
    padding: 0px;
}

.padding-20 {
    padding: 20px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-20-imp {
    padding-bottom: 20px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.margin-top-0 {
    margin-top: 0px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-7 {
    margin-top: 7px;
}

.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-bottom-0-imp {
    margin-bottom: 0px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-25 {
    margin-top: 25px;
}

.form-control {
    margin-bottom: 20px;
}

.padding-as25 {
    padding: 25px;
}

.lgray-bg {
    background: #efefef;
}

.content {
    /* margin-left: 10%;   
    margin-right: 10%; */
    justify-content: center;
    font-family: Roboto, sans-serif;


}

.sub_title {
    font-size: 20px;
    font-family: Roboto, sans-serif;
    color: #4a4a4a;
}

.para_content {
    font-size: 15px;
    font-family: Roboto, sans-serif !important;
    color: #4a4a4a !important;
    font-weight: 300;
    line-height: 1.5;

}

.para_content-new {
    font-size: 16px;
    font-family: Roboto, sans-serif;
}

.content_area {
    font-family: Roboto, sans-serif;
    margin: 0;
    font-weight: 400;
    color: #000;
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 35px;
    text-transform: capitalize;

}

.table_content {
    border: none;
    font-family: Roboto, sans-serif;
    width: 100%;
    font-size: 16px;
}

th,
td {
    border: none;
}

.ul_list {
    margin-left: 1%;
    font-size: 18px;
    font-family: poppins, sans-serif;
}


.block-heading {
    /* background: #efefef; */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    /* height: 50px; */
    /* margin-bottom: 30px; */
}

.block-heading .btn-sm {
    margin: 9px 15px 0 0;
}

.block-heading h4 {
    background-color: #0094e9;
    line-height: 50px;
    color: #ffffff;
    display: inline-block;
    padding-right: 20px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.block-heading .heading-icon {
    background: rgba(0, 0, 0, .1);
    width: 50px;
    text-align: center;
    margin-right: 20px;
    display: inline-block;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    position: relative;
}

.block-heading .heading-icon .icon-design {
    color: rgba(0, 0, 0, .1);
    font-size: 20px;
    position: absolute;
    right: -7px;
    top: 16px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
    display: none !important;
}

.slider-text {
    list-style: none;
    font-size: 100px;
    color: white;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}


a {
    -webkit-transition: none;
    transition: none;
}

.infobox .inner .image .overlay .wrapper a:hover {
    background: transparent;
}

.margin-left-15 {
    margin-left: 15px;
}

#about {
    background: #fff;
    padding: 30px 0 30px;
    top: 0;
}

.contain {
    width: 100%;
    position: relative;
    height: auto;
}

.inner {
    /* width: 100%; */
    /* max-width: 1170px; */
    /* margin: 0 auto; */
    /* padding: 40px 0; */
    /* position: relative;
    z-index: 2; */
}

#about .about-content {
    margin-left: 15%;
    width: 25%;
    height: auto;
    min-height: 276px;
    background: #fff;
    float: left;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#about .about-content:hover {
    background: #3c3c3c;
    color: #fff;
}

#about .about-content:hover .top {
    border: 2px solid #464646;
    background: #fff;
}

#about .about-content:hover .top i {
    color: #464646;
}

#about .about-content:hover .top:after {
    border: 2px solid white;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.visible {
    visibility: visible;
}

#about .about-content .top {
    width: 70px;
    height: 70px;
    background: transparent;
    border: 2px solid #464646;
    border-radius: 100px;
    padding: 20px 0;
    position: relative;
}

#about .about-content .icon {
    text-align: center;
    margin: 19px auto 16px;
}

#about .about-content .top i {
    font-size: 30px;
}

#about .about-content .top:after {
    position: absolute;
    background: transparent;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 500px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

#about .about-content .content-header {
    text-transform: uppercase;
    font-size: 20px;
}

#about .about-content .text {
    width: 95%;
    text-align: center;
    margin: auto;
}

#about .about-content .content-desc {
    font-size: 15px;
    color: #717171;
    margin-top: 10px;
    font-weight: 100;
}

#about .about-content:hover .content-desc {
    color: #fff;
}

#about .about-content .text {
    width: 95%;
    text-align: center;
    margin: auto;
}

#about .about-content .icon {
    text-align: center;
    margin: 19px auto 16px;
}

#about .about-content .bottom i {
    width: 31px;
    height: 31px;
    padding-top: 6px;
    font-size: 20px;
    color: #929292;
    background: transparent;
    border: 1px solid #464646;
    border-radius: 100px;
}

#about .about-content:hover .bottom i {
    border: 1px solid #fff;
    color: #fff;
}

#home {
    width: 100%;
    height: auto;
    position: relative;
}

/* .background-img {
    height: calc(100vh - 70px);;
    background-position: center;
    width: 100%;
    background-image: url(./homePageImage.png);
    background-size: cover;
} */

.main {
    position: absolute;
    width: 100%;
    top: 50%;
    margin: 0 auto;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    height: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

.home-desc {
    width: 100%;
    max-width: 670px;
    margin: 35px auto;
    position: relative;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
}

.home-button:active,
.home-button:focus {
    color: #fff;
}

.home-button:hover {
    color: #fff;
    background: black;
}

.home-button {
    width: 175px;
    height: auto;
    padding: 10px 23px;
    border: 1px solid white;
    background: rgba(43, 43, 43, 0.1);
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    cursor: pointer;
    margin: 35px auto;
    display: block;
    text-align: center;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: transparent;
}

.input-range__label--max .input-range__label-container {
    left: 0;
}

.input-range__label-container {
    color: #333;
}

.input-range {
    margin-top: 10px;
    width: 96%;
    margin-left: 8px;
}

.input-range__track--active,
.input-range__slider {
    background: #4c9cd3;
}

.input-range__slider {
    border: 1px solid #4c9cd3;
}

.btn,
select {
    padding: 6px 11px 6px 11px;
}

.btn-danger:hover,
.btn-danger:focus {
    background-color: #d73925 !important;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="number"],
input[type="tel"],
textarea.form-control {
    padding: 6px 9px 6px;
}

.items-list header {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 3px;
}

.items-list-private header {
    padding: 15px;
}

form.main-search .form-group {
    margin-right: 5px;
}

.items-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.items-list-private::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.items-list::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.items-list-private::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.items-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #9D9D9D;
}

.items-list-private::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D3D3D3;
}

.items-list {
    padding: 10px 0px 10px 0px;
}

.items-list-custom::-webkit-scrollbar-thumb {
    background-color: #f78e1c;
}

.block {
    padding-top: 10px;
}

.display-none-imp {
    display: none !important;
}

.display-none {
    display: none;
}

.display-block {
    display: block;
}

#main-content .page-title .info {
    display: table-cell;
    text-align: right;
}

#main-content .page-title .info .type i {
    border-radius: 50%;
    background-color: #fff;
    display: inline-block;
}

#main-content .page-title .info .type i img {
    opacity: 0.6;
    width: 20px;
}

#main-content .page-title .info .type span {
    opacity: 0.6;
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 5px;
}

ul.features-list {
    display: inline-block;
}

.rating .stars .fa.active,
address .info a,
.show-more:before,
.show-more:after,
ul.bullets li:before,
.btn.framed i,
select.framed i,
.rating.color .stars .fa.active {
    color: #4c9cd3;
}

.block {
    padding-top: 0;
}

#main-content #reviews header,
#main-content #write-review header {
    border-bottom: 1px solid #f4f4f2;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

#main-content #reviews .overall-rating,
#main-content #write-review .overall-rating {
    border-bottom: 1px solid #f4f4f2;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

#main-content #detail-sidebar section .rating,
#main-content #detail-sidebar section header h3 {
    margin: 0;
}

#page-content {
    background-color: #ffffff;
    height: 100%;
    width: 100%;
}

#main-content .page-title {
    border-bottom: 1px solid #f4f4f2;
    display: table;
    padding-bottom: 20px;
    width: 100%;
    border: none;
    margin-bottom: 0px;
    position: relative;
}

#main-content .page-title .title {
    display: table-cell;
}

#main-content .page-title h1 {
    font-size: 25px;
    margin-bottom: 5px;
    text-transform: none;
    margin-top: 0;
}

#main-content #detail-sidebar section {
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
}

#main-content header {
    border: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
    position: relative;
}

#main-content #detail-sidebar section address,
#main-content #detail-sidebar section figure {
    margin-top: 15px;
}

#main-content #detail-sidebar section address {
    margin-bottom: 0px;
}

/* home page css */
/* .mapboxgl-popup-content {
    height: 230px;
    position: relative;
    background: none;
    box-shadow: none;
    padding: 0;
} */

/* .leaflet-popup {
    position: relative;
} */

figure {
    margin: 0;
}

.modal .modal-dialog .modal-content {
    padding: 0;
}

.modal .modal-dialog .modal-content .modal-header {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.modal .modal-dialog .modal-body .features {
    height: auto;
    padding: 30px;
    color: #fff;
}

.modal .modal-dialog .modal-body .qv-body-content p {
    font-size: 14px;
}

.modal-dialog {}



.modal-body {
    /* background-color: #3a3a3a;
    font-size: 12px;
    margin-top: 10px;
    padding: 0;
    text-align: left;
    position: relative;
    padding-bottom: 0!important; */
}

.gallery {
    display: block;
    position: relative;
    width: 350px;
    float: left;
}

.modal-contents {
    border-radius: 0px;
    background-color: #fff;
    border: none;
    display: table-cell;
    vertical-align: top;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 80px;
    width: 350px;
}

/* .modal .modal-dialog .modal-content .modal-header .close {
    right: 5px;
} */

.modal {
    top: 0;
}

.price {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    background-color: #4c9cd3;
    color: #fff;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    left: 10px;
    padding: 3px 8px;
    position: absolute;
    top: -4px;
    z-index: 2;
}

/* .modal-body {
    background-color: #3a3a3a;
    display: table;
    font-size: 12px;
    margin-top: 30px;
    padding: 0;
    text-align: left;
    width: 820px;
    position: relative; 
}

.gallery {
    width: 350px;
}

.image {
    position: relative;
}

.type {
    background-color: #fff;
    padding: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.modal .modal-dialog .modal-body .features {
    height: auto;
    padding: 30px;
    color: #fff;
}

.modal-contents {
    border-radius: 0px;
    background-color: #fff;
    border: none;
    display: table-cell;
    vertical-align: top;
    padding: 30px;
    width: 100%;
} */

/* .owl-carousel {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
} */

/* body {
    color: #404040;
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    color: #404040;
    text-decoration: none;
}

a:hover {
    color: #101010;
}

.sidebar {
    position: absolute;
    width: 32%;
    height: 100%;
    top: 70px;
    left: 0;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.map {
    position: absolute;
    left: 32%;
    width: 68%;
    top: 70px;
    bottom: 0;
}

.heading {
    background: #fff;
    border-bottom: 1px solid #eee;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    text-align: center;
    background-color: #00853e;
    color: #fff;
}

.listings {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-bottom: 60px;
}

.listings .item {
    display: block;
    border-bottom: 1px solid #eee;
    padding: 10px;
    text-decoration: none;
}

.listings .item:last-child { border-bottom: none; }

.listings .item .title {
    display: block;
    color: #00853e;
    font-weight: 700;
}

.listings .item .title small { font-weight: 400; }

.listings .item.active .title,
.listings .item .title:hover { color: #8cc63f; }

.listings .item.active {
    background-color: #f8f8f8;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-left: 0;
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
    background: none;
}

::-webkit-scrollbar-thumb {
    background: #00853e;
    border-radius: 0;
}

.clearfix { display: block; }

.clearfix::after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.mapboxgl-popup-close-button {
    display: none;
  }
  
  .mapboxgl-popup-content {
    font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
    padding: 0!important;
    width: auto;
  }
  
  .mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
  }
  
  .mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    border-bottom-color: #91c949;
  }

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.margin-10 {
    margin: 10px;
}

.margin-15 {
    margin: 15px;
}

.padding-10 {
    padding: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.display-table-cell {
    display: table-cell;
}

.vertical-align-middle {
    vertical-align: middle;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-14 {
    font-size: 14px;
}

.font-weight-bold {
    font-weight: bold;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.color-white, .color-white:hover {
    color: white;
}

.overflow-auto {
    overflow: auto;
}

.height-100p {
    height: 100%;
}

.height-85p {
    height: 85%;
}

.width-100p {
    width: 100%;
}

.color-grey {
    color: #999;
}

.margin-top-30 {
    margin-top: 30px;
}

.padding-0-imp {
    padding: 0!important;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.font-color-link {
    color: #0c82a5;
    cursor: pointer;
}

.margin-bottom-20 {
    margin-bottom: 20px;
} */

.autocomplete-dropdown-container {
    /* position: absolute;
    top: 95%;
    background-color: white;
    /* border: 1px solid #555555;
    width: 92%;
    z-index: 1000; */

    width: 332px;
    background-color: #fff;
    position: absolute !important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    /* font-family: Arial,sans-serif; */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

autocomplete-dropdown-container.no-border {
    border: 'none',
}

.z-index-1000 {
    /*added by nayana*/
    z-index: 1000;
}

.z-index-0 {
    /*added by nayana*/
    z-index: 0;
}

.suggestion-item--active {
    padding-left: '10px';
    padding-right: '10px';
    padding-top: '5px';
    padding-bottom: '5px';
}

.padding-0 {
    padding: 0;
}

.btn.btn-default {
    background-color: #08406e;
}

.btn.btn-default:hover,
.btn.btn-default:focus {
    background-color: #077bda;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    background-color: #007bff;
    border-color: #007bff;
}

.font-weight-bold {
    font-weight: bold;
}

.font-weight-500 {
    font-weight: 500;
}

.multi-select .options {
    height: auto !important;
    max-height: 250px;
    overflow: scroll;
}

.multi-select .selected-options {
    /* min-height: 33.5px!important; */
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-15 {
    padding-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-120 {
    margin-bottom: 120px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-30-imp {
    margin-bottom: 30px !important;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.navbar-brand {
    height: auto;
}

.results::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.results::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.results::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #4c9cd3;
}

.height-100p {
    height: 100%;
}

.width-100p {
    width: 100%;
}

.height-215 {
    height: 215px;
}

.height-240-imp {
    height: 240px !important;
}

.width-360-imp {
    width: 360px !important;
}

.width-285 {
    width: 285px;
}

.height-40 {
    height: 40px;
}

select {
    font-weight: unset;
}

.breadcrumb,
.skin-blue .main-header .navbar {
    background-color: #0094e9;
}

.breadcrumb>.active {
    color: white;
}

.color-white,
.color-white:hover {
    color: white;
}

.color-dark-grey {
    color: #474747;
}

.color-blue {
    color: #008fe4;
}

/* .color-faint-blue {
    color: #0094e959;
} */

.color-black {
    color: #000000;
}

.padding-top-30 {
    padding-top: 30px;
}

.home-page-box {
    padding: 24px;
    border: 1px solid #eee;
    box-shadow: rgb(209, 209, 213) 0px 0px 4px;
    border-radius: 3px;
}

.margin-top-16 {
    margin-top: 16px;
}

.marign-top-0 {
    margin-top: 0;
}

.globalnavbar-header-container {
    position: relative;
    height: 110px;
}

.globalnavbar .globalnavbar-header-container .globalnavbar-header .logo-container {
    position: relative;
    float: left;
    top: 10px;
    margin-right: 22px;
    z-index: 1;
}

.globalnavbar .globalnavbar-header-container .globalnavbar-header .logo-container .globalnavbar-logo {
    height: auto;
    width: 90px;
}

.globalnavbar .globalnavbar-header-container .globalnavbar-header .globalnav-menu-container {
    float: left;
}

.globalnavbar .globalnavbar-header-container .globalnavbar-header .globalnav-menu-container .menu-item-container {
    position: absolute;
    bottom: 0;
}

.globalnavbar li.overlayMenuItem {
    float: left;
    border-top: 3px solid transparent;
}

.globalnavbar ul {
    list-style-type: none;
    margin: 0 10px 0 0;
    padding: 0;
}

.globalnavbar li.overlayMenuItem {
    float: left;
    border-top: 3px solid transparent;
}

.globalnavbar .overlayMenuItem {
    position: relative;
    float: left;
}

.globalnavbar li.overlayMenuItem h2.root-nav-heading {
    margin: 0;
    font-size: 16px;
}

.globalnavbar li.overlayMenuItem h2.root-nav-heading a {
    display: block;
    color: #215ca0;
    text-align: center;
    padding: 11px 15px;
    text-decoration: none;
    z-index: 100;
}

.globalnavbar .top-section {
    position: absolute;
    right: 15px;
    top: 0;
    width: 225px;
    height: 110px;
}

.globalnavbar .top-section .utility-bar {
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    display: block;
    float: right;
    text-align: right;
    min-width: 80vw;
    align-items: center;
    align-content: center;
    padding-top: 10px;
}

.globalnavbar .top-section .utility-bar>.search-form {
    margin-right: 10px;
    max-height: 35px;
}

.globalnavbar .top-section .search-form {
    margin-right: 20px;
}

.globalnavbar .search-form {
    width: 40px;
    transition: all .5s linear;
    margin-top: 0 !important;
}

.globalnavbar .top-section .utility-bar>div {
    display: inline-block;
    vertical-align: middle;
}

.text-night {
    color: #222;
}

.globalnavbar .top-section .utility-bar>div>.display-phone {
    margin-right: 10px;
    font-size: 16px;
}

.globalnavbar .dropdown a.dropdown-toggle {
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
}

.globalnavbar .top-section .free-trial-container {
    position: absolute;
    bottom: 0;
    right: 0;
}

.margin-0-top-lg {
    margin-top: 0;
}

.margin-0-bottom-lg {
    margin-bottom: 0;
}

.btn-container {
    display: inline-table;
}

.globalnavbar .top-section .free-trial-container .buttonCTAItemComponent {
    -webkit-transition: opacity .5s linear;
    -o-transition: opacity .5s linear;
    transition: opacity .5s linear;
    margin: 10px 0;
}

.globalnavbar .top-section .free-trial-container .buttonCTAItemComponent .btn-lg {
    font-size: 14px;
    padding: 0 20px;
}

.btn-container .btn {
    display: table-cell;
}

.btn-lg {
    padding: 0 24px;
    font-size: 14px;
    height: 42px;
}

.btn-lg,
.btn-xlg.simple-link {
    line-height: 100%;
    border-radius: 4px;
}

.btn-nav {
    color: #fff;
    background-color: #278806;
    text-transform: uppercase;
    transition: background-color .3s ease;
    font-family: SalesforceSansRegular;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-16-imp {
    font-size: 16px !important;
}

.font-size-18-imp {
    font-size: 18px !important;
}

.padding-left-right-0 {
    padding-left: 0;
    padding-right: 0;
}

.padding-left-right-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-left-right-20-imp {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.padding-left-right-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-25-imp {
    font-size: 25px !important;
}

.font-size-23-imp {
    font-size: 23px !important;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-22-imp {
    font-size: 22px !important;
}

.font-size-21 {
    font-size: 21px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-20-imp {
    font-size: 20px !important;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-14-imp {
    font-size: 14px !important;
}

.background-blue {
    background-color: #4c9cd3;
}

.background-blue-imp {
    background-color: #4c9cd3 !important;
}

.border-none-imp {
    border: none !important;
}

.background-white {
    background: white;
}

.custom-file-input {
    position: absolute;
    /* z-index:2; */
    top: 0;
    left: 0;
    opacity: 0;
    background-color: transparent;
    color: transparent;
    height: 40px;
    width: 100%;
}

.custom-file-input-label {
    background-color: #d8ebf8;
    color: #4c9cd3;
}

.border-bottom-1 {
    border-bottom: 1px solid #f4f4f2;
}

.list-group-item {
    background-color: unset;
    border: none;
}

input[type="checkbox" i] {
    height: unset !important;
    accent-color: #9D9D9D !important;
}

.css-yk16xz-control {
    border-color: #9D9D9D !important;
    box-shadow: #9D9D9D !important;
}

.css-1pahdxg-control {
    border-color: #9D9D9D !important;
    box-shadow: none !important;
}

.address-box {
    background: #f0f0f0;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
}

.clear-filters {
    position: absolute;
    width: 100%;
    top: 135px;
    background-color: #f5f5f5;
    min-height: 30px;
}

.margin-left-30 {
    margin-left: 30px;
}

.margin-top-30 {
    margin-top: 30px;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.top-12 {
    top: 12px;
}

.margin-left-25 {
    margin-left: 25px;
}

.price-tag {
    position: absolute;
    font-size: 20px;
    background: #4c9cd3;
    color: white;
    padding: 4px 10px 4px;
    left: 0;
}

.contact-us {
    position: absolute;
    bottom: 0px;
    font-size: 20px;
    background: #4c9cd3;
    color: white;
    padding: 10px 10px;
    left: 0;
    z-index: 1;
}

.background-faint-blue {
    background-color: #4c9cd31f;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-13 {
    font-size: 13px;
}

textarea {
    resize: vertical;
}

.magnifying-glass {
    width: 35px;
}

.browse-listings-banner {
    font-size: 38px;
    float: left;
    margin-left: 50px;
}

.text-normal {
    text-transform: none;
}

.padding-top-bottom-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: none;
    border-bottom: 3px solid #4c9cd3;
}

#tab-nav-header .nav-tabs>li {
    width: 33.33%;
}

#tab-nav-header .nav-tabs>li>a {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    color: black;
    opacity: 0.6;
    min-height: 135px;
}

.name-initials {
    padding: 6px;
    background: black;
    border-radius: 20px;
    font-weight: bold;
    color: #fff;
    /*added by nayana*/
}

@media only screen and (max-width: 767px) {
    #tab-nav-header-mobile .nav-tabs>li>a {
        font-size: 16px !important;
        min-height: 90px;
    }

    .font-size-24-xs {
        font-size: 24px;
    }
}

#tab-nav-header .nav-tabs>li.active>a {
    opacity: 1;
    background-color: #4c9cd3;
}

#tab-nav-header .nav-tabs>li.active>a>span,
#tab-nav-header .nav-tabs>li.active>a>div {
    color: white;
}

.border-bottom-3 {
    border-bottom: 3px solid #4c9cd3;
}

.display-flex {
    display: flex;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.custom-button {
    border-radius: 25px;
    margin-top: 30px;
    text-transform: none !important;
}

.custom-button-header {
    height: 40px !important;
    border-radius: 25px !important;
    line-height: 20px !important;
    text-transform: none;
    min-width: 90px;
}

.background-color-white-imp {
    background-color: white !important;
}

.background-color-grey {
    background-color: #f7f7f7;
}

.text-blue {
    color: #143256 !important;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-0 {
    margin-right: 0px;
}

.border-radius-25-imp {
    border-radius: 25px !important;
}

.modal-content {
    border: 4px solid #4c9cd3;
}

.cursor-pointer {
    cursor: pointer;
}

#blue-section {
    padding: 40px 0px;
    clear: both;
    margin-bottom: 0px;
    background-color: rgba(132, 216, 255, 0.1);
    /* background-image: linear-gradient(to right, #84D8FF, #4DAFFF) */
}

.padding-30 {
    padding: 30px;
}

.header-hover:hover {
    color: #eee !important;
}

.hover:hover {
    color: #c2c1c1 !important;
}

.hoverPrivate:hover {
    color: #c2c1c1 !important;
}

.background-video {
    max-height: 700px;
    opacity: 0.25;
}

.top-25p {
    top: 25%;
}

#tab-nav-header .nav-tabs>li>a {
    border: 1px solid black;
}

.modal .close {
    background: #eee !important;
}

.close {
    opacity: 1;
}

.contact-modal {
    width: 50%;
}

.custom-contact-modal {
    width: 65%;
}

.custom-contact-modal-95 {
    width: 95%;
}

#tab-nav-header .nav-tabs>li>a {
    opacity: 1;
}

.tab-color {
    color: #053359;
}

.word-break {
    word-break: break-word;
}

.word-break-all {
    word-break: break-all;
}

@media only screen and (min-width: 576px) {
    .more-filter-btn {
        display: table;
        height: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .padding-left-right-15-imp {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .margin-12 {
        margin: 12px;
    }

    .margin-5 {
        margin: 5px;
    }

    .margin-left-15 {
        margin-left: 15px;
    }

    .margin-right-15 {
        margin-right: 15px;
    }

    .margin-left-15-imp {
        margin-left: 15px !important;
    }

    .margin-right-15-imp {
        margin-right: 15px !important;
    }

    .margin-top-13 {
        margin-top: 13px;
    }

    .transform-0-150 {
        transform: translate(0%, 150%);
    }

    /* .modal-dialog { width: 500px; } */
    .padding-left-0 {
        padding-left: 0;
    }

    .padding-right-0 {
        padding-right: 0;
    }

    .custom-button {
        height: 50px !important;
        line-height: 50px !important;
    }

    .padding-30px {
        padding: 30px !important;
    }

    .clear-both-md {
        clear: both;
    }

    /* .custom-modal {
        width: 39%;
    } */

    .custom-modal-step3 {
        width: 85%;
    }

    .padding-40-lg {
        padding: 40px;
    }

    .min-height-250 {}
}

.custom-modal-details {
    width: 70%;
}

@media only screen and (max-width: 767px) {
    .position-fixed-xs {
        position: fixed;
    }

    .top-0-xs {
        top: 0;
    }

    .z-index-10-xs {
        z-index: 10;
    }

    .background-white {
        background: white;
    }

    .display-none-xs {
        display: none;
    }

    .display-none-xs-imp {
        display: none !important;
    }

    /* .mapboxgl-canvas{
        height: 100vh !important;
    } */
    .text-left-xs {
        text-align: left;
    }

    .sticky-more-filter-xs {
        /* position: -webkit-sticky; */
        position: fixed;
        top: 0;
        z-index: 10;
    }

    .overflow-y-scroll-xs {
        overflow-y: scroll;
    }

    .height-100p-xs {
        height: 100%;
    }

    .more-filter-btn {
        z-index: 1;
    }

    #tab-nav-header-mobile .nav-tabs>li {
        width: 33.33%;
    }

    #tab-nav-header-mobile .nav-tabs>li>a {
        font-size: 20px;
    }

    #tab-nav-header-mobile .nav-tabs>li.active>a {
        color: white;
        background-color: #4c9cd3;
    }

    #blue-section {
        padding: 40px 0px;
        clear: both;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .padding-15-xs {
        padding: 15px;
    }

    .padding-0-xs {
        padding: 0;
    }

    .padding-0-imp-xs {
        padding: 0 !important;
    }

    #filter-mobile-view {
        opacity: 1;
        transition: opacity 2s;
    }

    #filter-mobile-view.hide {
        opacity: 0;
    }
}

@media only screen and (max-width: 575px) {
    .map-canvas .results.grid li {
        width: 97.5% !important;
    }
}

@media only screen and (min-width: 1400px) {
    .real-estate-item-desc h3 {
        font-size: 13px !important;
    }
}

.in .event-type.info {
    border-color: #19b5fe;
    background-color: #19b5fe;
}

.in .event-type .event-indicator {
    transform: scale(1);
    opacity: 1;
}

.in .event-type.warning {
    border-color: #f7bc06;
    background-color: #f7bc06;
}

.in .event-type.error {
    border-color: #f2545b;
    background-color: #f2545b;
}

.menu-hover:hover {
    color: #FFF;
    font-weight: bold;
}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    flex-wrap: unset !important;
}

.bg {
    height: 100%;
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
}

.loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.5;
    z-index: 1;
}

/* Map cluster styles - start */

.map-unclustered-marker {
    cursor: pointer;
}

.map-cluster-marker {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cluster-point-count {
    font-weight: 500;
    color: #fff;
    margin: 0;
}

/* Map cluster styles - end */

.loader {
    position: relative;
    top: 50%;
    margin: 0 auto;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.homepage-header {
    position: relative;
    z-index: 2;
    background: transparent;
}

.homepage-header>.navbar-default {
    background-color: transparent;
    border-color: transparent;
}

.homepage-header>.navbar-default>a {
    color: white;
}

.block-hover {
    background-color: #4c9cd3;
}

.block-hover:hover,
.background-dark-blue {
    background-color: #053359;
}

.well {
    background-color: #053359;
    margin-bottom: 0;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

/* Custom styles for new filters row - start */

.custom-filter-title {
    font-size: 18px;
    line-height: 26px;
    margin: 20px 0px 0px;
    padding: 0px 15px;
    color: #143256;
}

.custom-filter-row {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 10px;
}

.btn.custom-filter-button {
    font-size: 13px;
    margin: 5px;
    border-radius: 24px;
    padding: 5px 10px;
    outline: none;

}

.custom-tooltip {
    background-color: #fff;
    position: absolute;
    border-radius: 4px;
    min-width: 300px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    margin: 5px;
}

.content-wrapper {
    min-height: calc(100vh - 75px);
}

footer {
    position: relative;
    z-index: 999;
}

.custom-tooltip-content-wrapper {
    padding: 10px;
}

.custom-tooltip-reverse-content {
    display: flex;
    flex-direction: column-reverse;
}

.custom-button-horizontal-indent {
    padding: 0px 15px;
}

.custom-tooltip-content-screen-height {
    overflow: auto;
    max-height: 100vh;
}

.custom-tooltip.responsive-custom-tooltip {
    width: 45%;
}

@media only screen and (max-width: 768px) {
    .custom-tooltip.responsive-custom-tooltip {
        width: 50%;
    }

}

@media only screen and (max-width: 640px) {
    .custom-tooltip.responsive-custom-tooltip {
        width: 60%;
    }
}

@media only screen and (max-width: 480px) {
    .custom-tooltip.responsive-custom-tooltip {
        width: 100%;
    }
}

@media only screen and (max-width: 380px) {
    .custom-tooltip {
        width: 100%;
        margin: 5px 0px 0px;
    }
}

@media only screen and (max-width: 400px) {
    .margin-top-30px {
        margin-top: 30px !important;
    }
}

.custom-tooltip-content {
    margin-bottom: 15px;
}

.custom-tooltip-button-wrapper {
    display: flex;
    justify-content: flex-end;
    clear: both;
}

.custom-tooltip-close-button {
    background-color: transparent;
    border-color: transparent;
    /* background-color: #07345a; */
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    outline: none,
}

/* Custom styles for new filters row - end */

.tooltip-inner {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.autocomplete-dropdown-container {
    width: 90%;
}

.hLrWNh,
.inhAeF {
    font-weight: bold !important;
    font-size: 14px !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.carousel .thumbs-wrapper {
    position: absolute;
    width: 95%;
}

/* .border-color-extra-light-gray {
    border-color: #ededed !important;
}

.padding-fifteen-all {
    padding: 15%;
}

.border-top {
    border-top: 1px solid #dee2e6!important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6!important;
}

.border-right {
    border-right: 1px solid #dee2e6!important;
}

.section-bg-blue {
    background-color: #009df5;
}

.padding-twelve-left {
    padding-left: 12%;
} */

.bg-extra-dark-gray {
    background-color: #1c1c1c;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.padding-ten-right {
    padding-right: 10%;
}

.icon-box {
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.width-100 {
    width: 100%;
}

.d-table {
    display: table !important;
}

.icon-box .icon-box-holder {
    padding-left: 74px;
    height: 112px;
}

.d-table-cell {
    display: table-cell !important;
}

.align-middle {
    vertical-align: middle !important;
}

.icon-box .icon-box-holder i {
    position: absolute;
    left: 0;
    vertical-align: middle;
    padding-top: 10px;
}

.icon-medium {
    font-size: 35px;
}

.text-extra-medium-gray,
.btn.text-extra-medium-gray {
    color: #757575;
}

.line-height-24 {
    line-height: 24px;
}

.text-large {
    font-size: 18px;
}

.padding-ten-left {
    padding-left: 10%;
}

.margin-20px-bottom {
    margin-bottom: 20px;
}

.section-bg-blue {
    background-color: #053359;
}

.display-inline-block {
    display: inline-block;
}

.padding-ten-all {
    padding: 10%;
}

.padding-five-all {
    padding: 5%;
}

.active {
    /* color: #019df5; */
    font-size: 16px;
    text-decoration: none;
    color: #f1f5f7f9;

}

.font-weight-600 {
    font-weight: 600;
}

.color-faint-blue {
    color: #91cdff;
}

.tooltip-inner {
    max-width: 300px;
    padding: 15px 11px;
}

.quick-view-modal {
    width: 50%;
}

@media only screen and (max-width: 1024px) {
    .quick-view-modal {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .quick-view-modal {
        width: 94%;
    }

    .reduce-padding-0 {
        padding: 0px !important;
    }

    .reduce-img {
        height: auto;
    }
}

@media only screen and (max-width: 1240px) {
    .banner-height {
        height: 100% !important;
    }

    .banner-overlay-height {
        height: 99% !important;
    }
}

@media only screen and (min-width: 1565px) {
    .padding-left-right-10p {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.min-height-255 {
    min-height: 255px;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .min-height-210 {
        min-height: 210px;
    }

    .padding-10-xs {
        padding: 10px;
    }

    .custom-modal-help-data {
        width: 70%;
    }
}

.modal-border {
    padding: 5px;
    background-color: #009df5;
    border-radius: 6px;
}

.mapboxgl-popup-content {
    padding: 0 !important;
}

.popup-listing-name {
    transition: .4s;
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 0;
    text-transform: none;
}

.temp {
    width: 90%;
    margin-left: 1%;
}

.temp1 {
    width: 99%;
    margin-left: -1%;
}

.tooltip {
    z-index: 1040;
}


#social-media-tooltip {
    z-index: 1050;
}

input[type=radio] {
    visibility: hidden;
}

input[type=radio]:checked~.check {
    border: 1px solid #0094e9;
}

.Step3New_radio {
    visibility: visible !important;
}

.check {
    display: block;
    position: relative;
    float: left;
    /* position: absolute; */
    border: 1px solid #616161;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    /* top: 26px; */
    /* left: 28px; */
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    margin-left: 2px;
    cursor: pointer;
}

input[type=radio]:checked~.check::before {
    background: #0094e9;
}

.check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check1 {
    border: 1px solid #0094e9;
}

.check1 {
    display: block;
    position: relative;
    float: left;
    /* position: absolute; */
    border: 1px solid #616161;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    /* top: 61px; */
    /* left: 28px; */
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    margin-left: 2px;
    cursor: pointer;
}

input[type=radio]:checked~.check1::before {
    background: #0094e9;
}

.check1::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

#tooltip1 {
    width: 350px;
    top: 200px !important;
}

#tooltip2 {
    width: 450px;
    max-width: 90%;
    top: 235px !important;
}

.tooltip-inner {
    max-width: 100%;
}

.quick-view-modal .close {
    right: 30px !important;
    margin-top: 12px;
}

.carousel .thumbs {
    margin-left: 3.5%;
    margin-right: 3.5%;
}

.items-list .inner .results li .item:hover .h3-hover {
    color: #f78e1c !important;
}

.color-orange {
    color: #f78e1c;
}

.label-hover:hover {
    text-decoration: underline;
}


.min-height-255 {
    min-height: 255px;
}

.draggableMarkercoordinates {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 100px;
    left: 20px;
    padding: 5px 10px;
    margin: 0;
    font-size: 11px;
    line-height: 18px;
    border-radius: 3px;
    display: none;
}

.draggableMarkerContent {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    position: absolute;
    bottom: 50px;
    left: 20px;
    padding: 5px 10px;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    border-radius: 3px;
    display: block;
}

.showsatalliteIconsStyle {
    background: rgb(172, 164, 164);
    color: #fff;
    position: absolute;
    left: 20px;
    top: 25px;
    padding: 5px 10px;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    border-radius: 3px;
    display: block;
    height: 50px;
    width: 50px;
    overflow: hidden;
}

.uploadInput {
    padding: 10px;
    border: 1px solid #ccc;
    background: linear-gradient(135deg, #ffa000 20%, #ff5000 80%);
    color: #fff;
    max-width: 500px;
    box-shadow: 2px 2px 5px 2px hsla(0, 0%, 0%, 0.15);
}

.imagePreviewContainer {
    display: flex;
    padding: 0px;
}

.previewItem {
    /* display: flex;
         */
    padding: 5px;
    margin: 5px;
    /* flex: 0 0 350px; */
    background: #fff;
    box-shadow: 1px 2px 5px 0px hsla(0, 0%, 0%, 0.35);
}

.imagePreview {
    max-width: 80px;
    height: 55px;
}

.details {
    padding: 10px;
}

.details h6 {
    font-size: 10pt;
    fong-weight: normal;
    color: #999;
    padding: 5px;
    margin: 0;
}

.details i {
    cursor: pointer;
}

@media only screen and (max-width: 1419px) {

    .visible-xs-custom,
    .navbar-toggle-custom,
    .navbar-collapse.nav-collapse-custom.in {
        display: block !important;

    }

    .hidden-xs-custom,
    .navbar-collapse.nav-collapse-custom {
        display: none !important;

    }

    .float-none,
    .nav-collapse-custom li {
        float: none;
    }

}

@media only screen and (min-width: 1420px) {

    .navbar-toggle-custom,
    .visible-xs-custom {
        display: none !important;

    }

    .hidden-xs-custom {
        display: block !important;

    }
}

@media only screen and (min-width: 1250px) {

    .homepage-image {
        display: block !important;
        width: 'auto';
        max-height: 740px;
        max-width: 750px;
        height: 'auto';

    }
}

@media only screen and (max-width: 1250px) {

    .homepage-image {
        display: none !important;

    }
}

@media only screen and (max-width: 790px) {

    .show-inline-footer {
        display: none !important;

    }

    .hide-inline-footer {
        display: block !important;

    }
}

@media only screen and (min-width: 791px) {

    .show-inline-footer {
        display: block !important;

    }

    .hide-inline-footer {
        display: none !important;

    }

    .increase-font-size-25 {
        font-size: 25px !important;
    }
}


/* @media only screen and (max-width: 1419px) {
    .visible-xs-custom{
        display: block !important;

    }
    .hidden-xs-custom{
        display: none !important;

    }
}
@media only screen and (min-width: 1420px) {
    .navbar-toggle-custom,.visible-xs-mid{
        display: none !important;

    }
    .hidden-xs-custom{
        display: block !important;

    }
}
@media only screen and  (min-width: 768px) and (max-width: 1419px) {
    .visible-xs-mid {
        display: block !important;
    
    }
    .visible-xs {
        display: none !important;
    
    }
    .hidden-xs{
        display: none !important;

    }
  }
  @media only screen and (min-width: 1420px) {
    
    .visible-xs {
    display: none !important;

}
    .hidden-xs{
        display: block !important;

    }
}
@media only screen and (max-width: 768px) {
    
    .hidden-xs,.visible-xs-mid{
    display: none !important;

}
    .visible-xs{
        display: block !important;

    }
} */

.css-12jo7m5 {
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    font-size: unset;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: break-spaces !important;
    box-sizing: border-box;
}

.css-1n7v3ny-option {
    background-color: #c2c1c1 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1355px) {
    .img-height-450 {
        height: 450px;

    }

    .custom-modal-help-data {
        width: 65%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1470px) {
    .img-height-525 {
        height: 525px;

    }
}

@media only screen and (min-width: 992px) and (max-width: 1355px) {
    .img-height-517 {
        height: 517px;

    }
}

@media only screen and (min-width: 768px) and (max-width: 940px) {
    .reduce-font-size-16 {
        font-size: 16px !important;
        margin-bottom: 20px !important;

    }

    .reduce-margin-top-10 {

        margin-top: -10px !important;

    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .reduce-font-size-18 {
        font-size: 18px !important;

    }

}


@media only screen and (max-width: 767px) {
    .reduce-font-size-15 {
        font-size: 15px !important;
        margin-bottom: 5px !important;

    }

    .reduce-font-size-20 {
        font-size: 20px !important;
    }

    .reduce-font-size-11 {
        font-size: 11px !important;
    }

    .left-allign-icons {
        left: 0px !important;
    }
}

@media only screen and (max-width: 788px) {
    .hidden-xs-user-name {
        display: none;
    }

}

@media only screen and (max-width: 425px) {
    .reduce-font-size-12px {
        font-size: 12px !important;
    }

}

@media only screen and (max-width: 515px) {
    .reduce-padding-size {
        padding-left: 5px !important;
        font-size: 10px;
    }

    .padding-12-15 {
        padding: 10px 5px 1px 1px;
    }

}

@media only screen and (min-width: 516px) {
    .padding-12-15 {
        padding: 12.5px 15px 12.5px 15px;
    }


}

@media only screen and (max-width: 1419px) and (min-width:996px) {

    .visible-xs-custom,
    .hidden-xs-custom {
        display: none !important;
    }

    .visible-xs-custom-mid {
        display: block !important;
    }

}

@media only screen and (max-width:1220px) {

    .hidden-xs-custom-mid,
    .hidden-xs-custom {
        display: none !important;
    }

    .visible-xs-custom {
        display: block !important;
    }

    nav>.open>a:hover {
        background-color: #FFF !important;
    }
}

@media only screen and (min-width:1419px) {

    .hidden-xs-custom-mid,
    .visible-xs-custom {
        display: none !important;
    }

    .hidden-xs-custom {
        display: block !important;
    }
}

.font-size-12 {
    font-size: 12px;
}

@media only screen and (min-width:768px) and (max-width:1400px) {

    .width-80 {
        width: 'auto';
    }
}

@media only screen and (min-width:1356px) {
    .custom-modal-help-data {
        width: 60%;
    }

    .iframe-width {
        max-width: 85% !important;
    }
}



@media only screen and (max-width:992px) {

    .reduce_padding_0 {
        padding: 1rem !important;
    }
}

@media only screen and (max-width:767px) {

    .reduce-margin-form {

        width: 99% !important;
        margin-left: 0 !important;
    }
}

.reduce-title-font-size {
    display: block;
}

.title-font-size {
    display: none;
}




@media only screen and (max-width:509px) {
    .adjust-img-below-510 {
        max-width: 90% !important;
        margin-left: 5%;
        max-height: 300px !important;
    }

}

@media only screen and (min-width:510px) and (max-width:767px) {

    .adjust-img-below-768 {
        max-width: 90% !important;
        margin-left: 5%;
        max-height: 400px !important;
    }
}

/* .scrollbar-safari {
    overflow-x: scroll !important;
    overflow: scroll !important;
} */

@media only screen and (max-width:767px) {
    .list-item-visible {
        display: block !important;
    }

    .gethelp-item-visible {
        display: block !important;
    }

    .gethelplarge-item-visible {
        display: none !important;
    }

    .reduce-padding-small {
        font-size: 15 px !important;
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .adjust_list_help_modal {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

}

@media only screen and (min-width:768px) {
    .list-item-visible {
        display: none !important;
    }

    .gethelp-item-visible {
        display: none !important;
    }

    .gethelplarge-item-visible {
        display: block !important;
    }

    .reduce-img-230 {
        height: 230px !important;
    }

    .title-font-size {
        display: block;
    }

    .reduce-title-font-size {
        display: none;
    }


}

@media only screen and (max-width:600px) {
    .adjust-margins-search-result {
        width: 100% !important;
        padding-left: 0px !important;
    }

}

@media only screen and (max-width:920px) {
    .add-new-line {
        display: block;
    }

}

@media only screen and (min-width:920px) {
    .add-new-line {
        display: none;
    }

}

@media only screen and (min-width:500px) {
    .reduce-image-height {
        height: '150px' !important;
    }

}

@media only screen and (min-width:500px) and (max-width:767px) {

    .adjust-img-size-modal {
        width: 70% !important;
        margin-left: 11%;

    }
}

@media only screen and (min-width:1010px) {
    .reduce-font-size-15px {
        font-size: 15px !important;
    }


}

@media only screen and (min-width:767px) and (max-width:983px) {
    .reduce-padding-to-0px {
        padding: 0px !important;
    }


}

@media only screen and (min-width:984px) {
    .padding-left-30px {
        padding-left: 35px !important;
    }


}

@media only screen and (min-width:991px) and (max-width:1119px) {
    .reduce-padding-to-0-privatefav {

        padding: 0px !important;
    }


}

@media only screen and (max-width:1055px) {
    .pull-left-below-1055 {
        float: left !important;
    }

}

@media only screen and (max-width:1200px) {
    .reduce-fontsize-15 {
        font-size: 15px !important;
    }

}

@media only screen and (max-width:1240px) {
    .reduce-height-banner {
        height: 500px !important;
    }

}

@media only screen and (max-width:319px) {
    .padding-top-50 {
        padding-top: 90px !important;
    }
}


.title-color-pdf {
    color: #009df5 !important
}

.title-color-pdf-fav {
    color: #07345a !important
}



@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    @media only screen and (min-width:768px) {

        .adjust-logo-dimentions::-webkit-full-page-media,
        :root .safari_only {
            max-width: 150 !important;
            max-height: 50 !important;
        }
    }
}

.react-multiple-carousel__arrow {
    position: fixed !important;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 0;
    cursor: pointer;
    display: none;
}

@media only screen and (min-width:1300px) {
    .adjust-image-data {
        max-width: 800px !important;
    }
}

@media only screen and (max-width:768px) {
    .make-width-300 {
        min-width: 300px !important;
    }

    .float-right-none {
        float: none !important;
    }
}

@media only screen and (min-width:768px) {
    .make-width-500 {
        min-width: 500px !important;
    }

    .adjust-contact-form {
        margin-left: -130px;
    }
}


.btn-success:hover {
    color: black;
    background-color: white !important;
    border-color: #398439 !important;
}

@media only screen and (min-width:1185px) {
    .hide-header-element {
        display: inline-block !important;
    }

    .adjust-top-homepage {
        position: absolute;
        top: 20%;
    }

    .image-background-home {
        height: 830px;
    }
}

@media only screen and (max-width:1184px) {
    .hide-header-element {
        display: none !important;
    }

    .adjust-top-homepage {
        position: absolute;
        top: 12%;
    }

    .image-background-home {
        height: 790px;
    }
}

@media only screen and (max-width:1199px) {
    .reduce-img {
        max-height: 200px !important;
    }
}

.css-at12u2-loadingIndicator {
    display: none !important;
}

.css-1xh8qep-loadingIndicator {
    display: none !important;
}

.page-title-text {
    font-size: 50px !important;
    font-weight: 600 !important;
    font-family: TeXGyreAdventor-Bold !important;
}

.video {
    margin: 30px auto;
    position: relative;
    display: block;
}

.video {
    /* border-bottom: 5px solid transparent; */
    box-shadow: 0px 20px 20px -10px #4e4e4e;
    /* border-image: linear-gradient(to right, #D04A02 20%, #FFB600 20%, #FFB600 40%, #E0301E 40%, #E0301E 60%, #EB8C00 60%, #EB8C00 80%, #D93954 80%, #D93954 100%); */
    border-image-slice: 1;
}

.video-homepage-bg {
    z-index: 10;
    padding: 10px;
    background-color: rgba(0, 123, 252, 0.1);
    visibility: visible;
    overflow: hidden;
    border-color: rgba(255, 255, 255, 0);
    border-style: none;
    margin: 0px;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    width: 350px;
    height: 70px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}

.video-homepage-button {
    z-index: 11;
    background-color: rgb(0, 123, 255);
    font-family: Roboto;
    text-transform: uppercase;
    visibility: visible;
    text-align: center;
    line-height: 60px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 20px;
    border-color: rgba(255, 255, 255, 0);
    border-style: none;
    box-shadow: rgb(0 122 255 / 20%) 0px 7px 13px 0px;
    margin: 0px;
    border-radius: 5px;
    padding: 0px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    width: 60px;
    height: 50px;
    min-height: 50px;
    min-width: 60px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    position: absolute;
    display: block;
    transform-origin: 50% 50% 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    visibility: visible;
    overflow: hidden;
}

@media only screen and  (max-width:393px){
    .adjust-top-margin {
        margin-top: 0%;
    }
}

@media only screen and  (max-width:435px) and (min-width:394px){
    .adjust-top-margin {
        margin-top: 0%;
    }
}
@media only screen and (max-width:502px) and (min-width:436px){
    .adjust-top-margin {
        margin-top: 0%;
    }
}

@media only screen and (max-width:767px){
    .hide-for-mobile {
       display: none;
    }
}


